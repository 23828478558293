.base-container {
  display: flex;
  flex-direction: column;
  min-width: 1150px;
}

a,
a:hover {
  text-decoration: none;
  color: rgb(90, 90, 90);
}
.active {
  color: brown;
}

.main-container {
  display: flex;
  min-height: 86vh;
}

.menu-container {
  display: flex;
  min-width: 250px;
  background-color: white;
  flex-direction: column;
  align-items: center;
}

.menu-top {
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
}
.menu-bottom {
  display: flex;
  width: 100%;
  margin-top: 6em;
  padding-bottom: 20px;
  background-color: white;
  flex-direction: column;
  align-items: center;
}

.menu-item {
  color: rgb(90, 90, 90);
  font-size: 1.4rem;
  line-height: 2.8rem;
  font-weight: 500;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
}

.menu-item-active {
  background-color: #f3f2f1;
}

.menu-item:hover {
  box-shadow: 4px 0px 0px 0px #0078d4 inset;
  background-color: #ecf0f7;
}

.submenu-item {
  color: rgb(90, 90, 90);
  background-color: #f1f0f0a2;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  width: 100%;
  cursor: pointer;
}

.submenu-item-active {
  background-image: linear-gradient(to right, #e9e9e9, #f7f8fa);
}

.submenu-item:hover {
  box-shadow: 4px 0px 0px 0px #0078d4 inset;
  background-color: #f7f8fabb;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-content {
  padding: 30px;
  padding-top: 15px;
}
.content-header {
  width: fit-content;
}

.top-bar {
  background-color: white;
  padding: 20px;
}

.top-bar-right {
  display: flex;
  align-items: center;
}
.top-bar-logo {
  max-width: 300px;
}

.top-user-photo {
  width: 60px;
  margin-right: 10px;
}

.top-user-name {
  font-weight: 600;
}

.top-bar-text {
  font-size: 2em;
  margin-left: 20px;
  color: rgb(102, 102, 102);
}
