.stat-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.stat-col {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.counter-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 180px;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  -moz-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}

.header__financial__burndown{
  display: flex;
  width:100%;
  justify-content: space-between;
}

.header__fb_button {
  width:100%
}

.counter-box-header {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 100%;
  padding: 5px;
}
.counter-box-header-line {
  border-top: 2px solid rgb(5, 91, 245);
  width: 50px;
}

.counter-box-title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.counter-box-body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.counter-box-content {
  font-size: 3rem;
  font-weight: 700;
}

.chart-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 400px;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  -moz-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}

.chart-box-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80%;
  width: 92%;
  min-height: 400px;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  -moz-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}

.chart-box-double {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60%;
  min-height: 400px;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  -moz-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}
