.project-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 50px;
}
.project-col {
  display: flex;
  flex-direction: column;
  align-content: left;
}

.flex-container {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: normal;
}

.toast-width-20 {
  width: 20em;
}
.toast-width-25 {
  width: 25em;
}
.toast-width-30 {
  width: 30em;
}
.toast-width-35 {
  width: 35em;
}
.toast-width-40 {
  width: 40em;
}
.toast-width-45 {
  width: 45em;
}
.toast-width-50 {
  width: 50em;
}