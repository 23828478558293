.finance-list {
  overflow-y: auto;
  max-height: 30em;
}

.finance-list {
  scrollbar-width: thin;
  scrollbar-color: rgb(179, 179, 179) rgb(243, 243, 243);
}

/* Works on Chrome, Edge, and Safari */
.finance-list::-webkit-scrollbar {
  width: 6px;
}

.finance-list::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
}

.finance-list::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
  border-radius: 20px;
  border: 1px solid rgb(243, 243, 243);
}

.finance-list-item {
  background-color: #a2e3f780;
  border-radius: 5px;
  font-size: 1rem;
}

.finance-list-total {
  font-size: 1rem;
}

.finance-list-item:hover {
  background-color: #68bdd6;
  cursor: pointer;
}
