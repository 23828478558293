/* Works on Firefox */
.at-result {
  scrollbar-width: thin;
  scrollbar-color: rgb(179, 179, 179) rgb(243, 243, 243);
}

/* Works on Chrome, Edge, and Safari */
.at-result::-webkit-scrollbar {
  width: 6px;
}

.at-result::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
}

.at-result::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
  border-radius: 20px;
  border: 1px solid rgb(243, 243, 243);
}

.at-result-div {
  font-family: "Courier New", Courier, monospace;
  background-color: white;
  overflow: auto;
  margin-left: 1.2em;
  font-size: 0.9rem;
  max-width: 80em;
  max-height: 80em;
  color: rgb(37, 37, 37);
}
