/*
.content-details-col {
  border: 1px solid rgb(0, 89, 255);
}

.content-details-row {
  border: 1px solid black;
}
*/
.content-details-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 1em;
}
.content-details-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding: 1em;
}

.details-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    background-color: white;
}
.details-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding: 0;
}

.details-title {
    width: 100%;
    font-size: 1em;
    padding: 5px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
}

.details-text {
    display: flex;
    width: 100%;
    padding: 5px;
    height: 100%;
    align-items: center;
}
.details-text-center {
    justify-content: center;
}

.details-pm-photo {
    float: left;
    width: 30px;
    margin-right: 10px;
}

.details-left-border-dashed {
    border-left: 1px dashed #dadada;
}

.details-summary-box {
    background-color: rgb(249, 249, 249);
    padding: 1.5em;
}

.details-summary-box-clickable {
    margin-top: 0.4em;
    background-color: rgb(249, 249, 249);
    padding: 0.5em;
}
.details-summary-box-clickable:hover {
    background-color: white;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
}

.content-summary-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.i-inside2 {
    pointer-events: none;
    position: absolute;
    top: 0.7em;
    left: 8em;
}

.content-project-updates {
    padding-top: 1em;
    overflow: auto;
}

/* Works on Firefox */

.content-project-updates {
    scrollbar-width: thin;
    scrollbar-color: rgb(179, 179, 179) rgb(243, 243, 243);
}

/* Works on Chrome, Edge, and Safari */
.content-project-updates::-webkit-scrollbar {
    width: 6px;
}

.content-project-updates::-webkit-scrollbar-track {
    background: rgb(226, 226, 226);
}

.content-project-updates::-webkit-scrollbar-thumb {
    background-color: rgb(179, 179, 179);
    border-radius: 20px;
    border: 1px solid rgb(243, 243, 243);
}
