.search-box {
  display: flex;
  flex-direction: row;
  align-items: left;
  width: auto;
  padding: 5px;
  background-color: white;
}

.search-box-input {
  border: 1px solid rgb(175, 175, 175);
}

.search-select {
  background-color: rgb(233, 233, 233);
}

.project-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 240px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  cursor: pointer;
}

.user-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 200px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  cursor: pointer;
}

.project-box-header {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 100%;
  padding: 5px;
  margin-bottom: 0.5rem;
}
.project-box-header-line {
  border-top: 2px solid rgb(5, 91, 245);
  width: 50px;
}

.project-box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-box-title-text {
  font-size: 1rem;
  font-weight: 600;
}

.project-box-body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: left;
}

.project-box-content {
  font-size: 1rem;
}

.project-manager-photo {
  float: right;
  width: 40px;
  margin-right: 10px;
}

.status-circle-mid {
  width: 20px;
  height: 20px;
  /*margin-right: 45px;*/
  margin-bottom: -3px;
  background-color: #f59f05;
  border-radius: 50%;
}

.status-circle-low {
  width: 20px;
  height: 20px;
  /*margin-right: 45px;*/
  margin-bottom: -3px;
  background-color: #5bf50b;
  border-radius: 50%;
}

.status-circle-high {
  width: 20px;
  height: 20px;
  /*margin-right: 45px;*/
  margin-bottom: -3px;
  background-color: #f5055b;
  border-radius: 50%;
}

.flex-table {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: normal;
}

.flex-tr {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-td {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: normal;
  padding-bottom: 6px;
}

.flex-td-left {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: baseline;
  align-content: normal;
  padding-bottom: 6px;
}

.flex-td-right {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: baseline;
  align-content: normal;
  padding-bottom: 6px;
}

.flex-item {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding-left: 10px;
  padding-right: 10px;
}
