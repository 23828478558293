.graph-row {
  display: flex;
  height: 100%;
  width: 100%;
}
.graph-col {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.graph-i-row {
  display: flex;
}

.graph-stacked-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stacked-bar-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #f5055b;
  padding-top: 2px;
  width: 80%;
  height: 16em;
}

.stacked-bar-remaining {
  width: 100%;
  background-color: #5bf50b;
}

.stacked-bar-remaining:hover {
  background-color: #8dfb52;
}

.stacked-bar-spent {
  width: 100%;
  background-color: #055bfb;
}

.stacked-bar-spent:hover {
  background-color: #2d77ff;
}

.graph-legend {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.graph-legend-item {
  display: flex;
  flex-direction: column;
}

.graph-legend-item-row {
  display: flex;
  flex-direction: row;
}

.legend-color-main {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  background-color: #f5055b;
}

.legend-color-remaining {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  background-color: #5bf50b;
}

.legend-color-spent {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  background-color: #055bfb;
}

.legend-item-name {
  margin-left: 1em;
  font-weight: 600;
}

.legend-item-value {
  color: dimgrey;
  margin-left: 1.8em;
  font-weight: bold;
  font-size: 1.4em;
}

.bar-graph-legend {
  font-size: 0.9rem;
  font-weight: 600;
}

.bar-graph-legend-blue {
  margin-top: 0.6em;
  margin-right: 0.2em;
  width: 2em;
  height: 0.5em;
  background-color: #055bfb;
  float: left;
}

.bar-graph-legend-green {
  margin-top: 0.6em;
  margin-right: 0.2em;
  width: 2em;
  height: 0.5em;
  background-color: #5bf50b;
  float: left;
}
