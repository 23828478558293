
.loginFormContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url("../images/ppm-one-login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#LoginForm {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid rgb(197, 197, 197);
  background-color: white;
  -webkit-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  -moz-box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}
.loginSlogan {
  margin-top: 10px;
  color:rgb(104, 104, 104);
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}

.hDivider {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.signInWithMicrosoft {
  display: flex;
  justify-content: center;
}

.form-error {
  color: red;
  padding-left: 20px;
}

.msError {
  margin-top: 20px;
}
