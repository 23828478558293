.task-box-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  /* margin-right: 20px; */
  margin-bottom: 20px;
  background-color: white;
}

.task-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  cursor: pointer;
}

.task-strike {
  text-decoration: line-through;
}

.task-box-header {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 100%;
  padding: 5px;
  margin-bottom: 0.5rem;
}
.task-box-header-line {
  margin-left: 1.4em;
  border-top: 2px solid rgb(5, 91, 245);
  width: 50px;
}

.task-box-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.task-box-title-text {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
}

.task-box-body-hidden {
  display: none;
}

.task-box-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: left;
  height: 7em;
}

.task-box-body-modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: left;
}

.task-box-content {
  overflow: auto;
  margin-left: 1.2em;
  font-size: 0.9rem;
  color: rgb(100, 100, 100);
}

.task-box-content-modal {
  height: 100%;
  margin-left: 1.2em;
  font-size: 0.9rem;
  color: rgb(100, 100, 100);
}

.task-box-checklist {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.task-box-checklist-icon-wrap {
  display: flex;
}

.task-box-checklist-icon {
  margin-top: 0.1em;
}

.task-box-checklist-icon:hover {
  font-weight: bold;
}

.task-box-checklist-icon:hover::before {
  content: "✓";
  margin-right: 0.23em;
  color: green;
}
.task-box-checklist-icon-done:hover::before {
  content: "✖";
  margin-right: 0.18em;
  color: red;
}

.ms-Icon:hover {
  cursor: pointer;
}

.task-box-checklist-name {
  display: flex;
  margin-left: 1em;
}

.task-manager-photo {
  float: right;
  width: 40px;
  margin-right: 10px;
}

.task-box-footer {
  pointer-events: none;
  font-size: 0.75rem;
  display: flex;
  width: 100%;
  color: #9e9e9e;
}

.task-box-comments {
  width: 100%;
}

/* Works on Firefox */
.task-box-content,
.modal-body {
  scrollbar-width: thin;
  scrollbar-color: rgb(179, 179, 179) rgb(243, 243, 243);
}

/* Works on Chrome, Edge, and Safari */
.task-box-content::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
  width: 6px;
}

.task-box-content::-webkit-scrollbar-track,
.modal-body::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
}

.task-box-content::-webkit-scrollbar-thumb,
.modal-body::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
  border-radius: 20px;
  border: 1px solid rgb(243, 243, 243);
}

.task-comment-main {
  width: 100%;
  display: flex;
  padding-left: 1em;
  padding-right: 1em;
}

.task-comment-photo {
  padding: 0.2em;
  width: 28px;
}

.task-comment-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.task-comment-user {
  width: 100%;
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: rgb(116, 116, 116);
}
.task-comment-text {
  font-size: 0.9rem;
  color: black;
}
