@font-face {
  font-family: D-DIN;
  src: url("./assets/fluentui.icons/fonts/d-din/D-DIN.otf") format("truetype");
}

* {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  font-family: D-DIN, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: block;
  align-items: center;
  background-color: rgb(117, 117, 117);
}

.top-border-dashed {
  border-top: 1px dashed #b1b1b1;
}
.left-border-dashed {
  border-left: 1px dashed #b1b1b1;
}
.bottom-border-dashed {
  border-bottom: 1px dashed #b1b1b1;
}

.hidden {
  display: none !important;
}

.ppm-select__menu {
  z-index: 99 !important;
}

.text-danger {
  color: #f5055b !important;
}

.table td,
.table th {
  padding: 0.3rem !important;
}
