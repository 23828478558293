.report-content {
  font-size: 0.9rem;
  font-weight: normal;
  background-color: white;
}

.report-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2em;
  /*background-color: rgb(158, 0, 127);&*/
  color: white;
}

.report-header-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.report-header-right {
  display: flex;
  justify-content: flex-end;
}

.report-header-company-name {
  font-size: 2rem;
  font-weight: 600;
}

.report-header-project-name {
  font-size: 1.2rem;
}

.report-logo-wrap {
  display: flex;
  align-items: center;
  max-height: 80px;
  max-width: 134px;
}

.report-title {
  padding: 0.8em;
  text-align: center;
  min-height: 3em;
  width: 100%;
  font-size: 2.2rem;
  /*color: rgb(158, 0, 127);*/
}

.report-details-container {
  padding: 1em;
}

.report-details-container table,
.report-details-container th,
.report-details-container tr,
.report-details-container td {
  border-color: white;
}

.report-details-container table tr td {
  vertical-align: center !important;
}

.report-section {
  padding: 0.5em 0 0.5em 0;
}

.report-section-header {
  /*color: rgb(158, 0, 127);*/
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid grey;
}

.report-table-head th {
  font-weight: 500;
  color: white;
}

.report-translucent {
  background-color: rgba(255, 255, 255, 0.85);
}

.report-status-summary-container {
  display: flex;
  justify-content: space-around;
}

.report-status-box table {
  /*background-color: rgb(158, 0, 127);*/
  padding: 0;
  margin: 0;
}
.expanding-textarea {
  background: none;
  width: 100%;
  min-height: 1rem;
  padding-left: 0.8em;
}

.expanding-list {
  padding-left: 1em;
}
.expanding-list li {
  list-style-type: none;
}
.expanding-list li:before {
  content: "•";
  font-size: 1.2em;
  position: absolute;
}
