.txt-lg {
    font-size: 1rem;
    font-weight: 500;
    color: #6e6e6e;
}
.txt-sm {
    font-size: 0.8rem;
    font-weight: 600;
    color: #6e6e6e;
}

.txt-sm-l {
    font-size: 0.9rem;
}

.task-name {
    padding-top: 0.35em;
    font-weight: 600;
}

.input-task-name {
    margin-top: 0.35em;
    padding: 0.2em !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgb(146, 146, 146);
    height: 1.8em !important;
    background-color: #f3f2f1 !important;
    border-radius: 0 !important;
    font-size: 0.95rem !important;
    font-weight: 500;
}

.input-checklist {
    margin-top: 0.35em;
    margin-left: 1em;
    display: revert;
    padding: 0.2em !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    height: 1.5em !important;
    background-color: #f3f2f1 !important;
    border-radius: 0 !important;
    font-size: 0.9rem !important;
    color: rgb(29, 29, 29);
}

.del-checklist {
    display: inline;
    position: absolute;
    margin-left: 0.4em;
    top: 0.35em;
    cursor: pointer;
}

.del-checklist:hover {
    color: red;
}

.check-mark {
    display: inline;
    position: absolute;
    top: 0.23em;
}

[type="radio"] {
    height: 1.6em;
    width: 1.6em;
}
.react-datepicker-popper {
    left: -50px !important;
}
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-default {
    font-size: 1.1rem;
    height: 2em;
    margin: 10px;
}

.currency {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.i-btn {
    border-radius: 0;
    width: 1.8em;
    height: 1.8em;
    margin-top: 0.5em;
    padding: 0.1rem 0.1rem;
    color: black;
    border: 1px solid rgb(109, 109, 109);
    background-color: white;
}

.i-inside {
    pointer-events: none;
    position: absolute;
    top: 0.5em;
    left: 7.2em;
}

.code {
    font-family: Consolas, SFMono-Regular, Menlo, Monaco, "Liberation Mono", "Courier New", monospace !important;
}

.due-date {
    padding: 0.2em !important;
    height: 1.8em !important;
    width: 7em !important;
    background-color: rgba(255, 255, 255, 0.877) !important;
    border-radius: 0 !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
}

.ov-bucket-name {
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: underline;
}

.member-picker {
    font-size: 0.8em;
    background-color: none;
}

.tiny {
    font-size: 0.7rem;
    font-weight: 500;
    color: dimgray;
}

.ov-task-name {
    margin-left: 0.6em;
    font-weight: 600;
    font-size: 0.8rem;
}
.ov-task-dates {
    font-size: 0.6rem;
    margin-left: 2.2em;
    font-size: 0.8rem;
}

.add-project-footer {
    width: 100%;
}
.toast-width-20 {
    width: 20em;
}
.toast-width-25 {
    width: 25em;
}
.toast-width-30 {
    width: 30em;
}
.toast-width-45 {
    width: 45em;
}
.toast-width-50 {
    width: 50em;
}
/*
.form-row {
    border: 1px solid rgb(255, 0, 0);
}

.col {
    border: 1px solid rgb(0, 89, 255);
}

.row {
    border: 1px solid black;
}
*/
